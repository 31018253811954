import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../Modal/Modal";
import classNames from "classnames";

import "./VariantPropertiesSelector.css";
import { useVariantPropertiesSelectorQuery } from "../../Generated/graphql";
import { Loading } from "../Loading/Loading";

type Props = {
  selectedProperties?: string[];
  onSelectProperties: (selectedProperties: string[]) => void;
  onCancel: () => void;
};

export const VariantPropertiesSelector: FC<Props> = ({
  selectedProperties: selectedPropertiesDefault = [],
  onCancel,
  onSelectProperties,
}) => {
  const { data, loading } = useVariantPropertiesSelectorQuery();

  const [selectedProperties, setSelectedProperties] = useState<string[]>(
    selectedPropertiesDefault
  );

  // Reset the selected properties when the default changes
  useEffect(() => {
    setSelectedProperties(selectedPropertiesDefault);
  }, [selectedPropertiesDefault]);

  /**
   * Toggle a variant
   */
  const handleToggleVariant = (variantId: string) => {
    if (isSelected(variantId)) {
      setSelectedProperties(
        selectedProperties.filter((id) => id !== variantId)
      );
    } else {
      setSelectedProperties([...selectedProperties, variantId]);
    }
  };

  /**
   * Determine if a variant has been selected
   */
  const isSelected = useCallback(
    (variantId: string) => {
      return selectedProperties.includes(variantId);
    },
    [selectedProperties]
  );

  const selectedCount = useMemo(() => {
    return selectedProperties.length;
  }, [selectedProperties]);

  const ModalPrimaryButton = {
    text: "Gem",
    onClick: () => onSelectProperties(selectedProperties),
  };

  const modalSecondaryButton = {
    text: "Fortryd",
    onClick: () => onCancel(),
  };

  return (
    <div className="VariantPropertiesSelector">
      <Modal
        primaryButton={ModalPrimaryButton}
        secondaryButton={modalSecondaryButton}
        className="VariantPropertiesSelector__Modal"
      >
        <div className="VariantPropertiesSelector__Headline">
          Konfigurér variant
          {selectedCount > 0 && <>&nbsp;({selectedCount})</>}
        </div>
        {loading === true && (
          <div className="VariantPropertiesSelector__Loading">
            <Loading />
          </div>
        )}
        {loading === false && (
          <div className="VariantPropertiesSelector__Properties">
            {data?.properties.map((property, i) => (
              <div
                key={i}
                className={classNames("VariantPropertiesSelector__Property", {
                  "VariantPropertiesSelector__Property--selected": isSelected(
                    property.id
                  ),
                })}
                onClick={() => handleToggleVariant(property.id)}
              >
                <div className="VariantPropertiesSelector__PropertyCheckbox">
                  <input
                    type="checkbox"
                    checked={isSelected(property.id)}
                    readOnly
                  />
                </div>
                <div className="VariantPropertiesSelector__PropertyName">
                  {property.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};
